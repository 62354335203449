.btn1 {
    font-weight: 300;
    /*transition: 0.3s;*/
    /*transition: 1s;*/
}

.btn2 {
    /*content: 'copied';*/
    /*font-weight: 900;*/
    /*transition: 1s;*/
    animation: erase 1.5s;
}

@keyframes erase {
    0% {font-weight: 900}
    50% {font-weight: 900}
    100% {font-weight: 300}
}

.fade {
    animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}

/*.btn1:not( :hover ) {*/
/*.btn1:hover {*/
/*    font-weight: 900;*/
/*}*/